import React from "react";
import NavBar from "../components/organisms/NavBar";
import '../App.css';



export default function Projects() {
  return (

    <div className="App">
      <header className="App-header">
      <NavBar/>

      </header>
    </div>
    
  );
}